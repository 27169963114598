<template>
  <span
    @click.prevent="openModal()"
    class="btn select-none cursor-pointer text-medium-xs text-red-600 hover:text-red-800"
  >
    <slot name="delete" v-bind="{ label: label }">
      <danger-button class="btn" type_color="secondary">
        <i class="fal fa-trash"></i>
        <space-icon />
        <span>{{ label }}</span>
      </danger-button>
    </slot>
  </span>
  <TransitionRoot as="template" v-if="open" :show="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="closeModal()"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block align-bottom bg-white rounded-lg px-8 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
          >
            <div class="absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                @click="closeModal()"
              >
                <span class="sr-only">Close</span>
                <i class="h-6 w-6 fal fa-times"></i>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left sm:w-full">
                <DialogTitle>
                  <dat-subtitle :label="title" />
                </DialogTitle>

                <div class="py-5">
                  <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="col-span-6 sm:col-span-3">
                      <dat-text>{{ message }}</dat-text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 flex flex-row-reverse">
              <danger-button-ghost v-if="processing">
                <i class="fal fa-spinner fa-spin"></i>
                <space-icon />
                <span>{{ processing_label }}</span>
              </danger-button-ghost>
              <danger-button
                v-else
                class="btn"
                @click.prevent="$emit('destroy-event')"
              >
                <i class="fal fa-trash"></i>
                <space-icon />
                <span>{{ label }}</span>
              </danger-button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

export default {
  name: "DestroyButton",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  emits: ["destroy-event", "update:modelValue"],
  data() {
    return {
      open: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Eliminar",
    },
    processing: {
      type: Boolean,
      default: false,
    },
    processing_label: {
      type: String,
      default: "Eliminando",
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal: function () {
      this.open = true;
    },
    closeModal: function () {
      this.open = false;
    },
  },
  updated() {
    this.open = this.modelValue;
  },
  watch: {
    open: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
