export default {
  data() {
    return {
      std_err: {
        display: false,
        title: null,
        message: null,
        messages: [],
        fields: [],
      },
    };
  },
  methods: {
    loadStandardErrorMessages: function ({
      title,
      message = null,
      messages = [],
      fields = [],
    }) {
      this.std_err.display = true;
      this.std_err.title = title;
      this.std_err.message = message;
      this.std_err.messages = messages;
      this.std_err.fields = fields;

      this.moveScrollToStandardErrorAlert();
    },
    moveScrollToStandardErrorAlert: function () {
      const el = document.querySelector("#standard_error_messages_alert");

      if (el) {
        el.scrollIntoView();
      } else {
        setTimeout(this.moveScrollToStandardErrorAlert, 100);
      }
    },
    isInvalid: function (field_name) {
      return this.std_err.fields.includes(field_name);
    },
    isInvalidLabel: function (field_name) {
      return this.std_err.fields.includes(field_name)
        ? "form-label-invalid"
        : "form-label";
    },
    clearStandardErrorMessages: function () {
      this.std_err.display = false;
      this.std_err.title = null;
      this.std_err.message = null;
      this.std_err.messages = [];
      this.std_err.fields = [];
    },
  },
};
