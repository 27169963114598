<template>
  <primary-button-disabled v-if="processing" :full="full">
    <i class="fal fa-spinner fa-spin" />
    <space-icon />
    <span class="text-gray-200">{{ label }}</span>
  </primary-button-disabled>
  <primary-button v-else class="btn" :full="full">
    {{ label }}
  </primary-button>
</template>

<script>
export default {
  name: "SaveButton",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "Crear",
    },
    processing: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
