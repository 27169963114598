<template>
  <div class="flex items-center justify-center">
    <img :class="[set_size, 'rounded-full']" :src="url" />
  </div>
</template>

<script>
const size_image = {
  8: "h-8 w-8",
  9: "h-9 w-9",
  10: "h-10 w-10",
  11: "h-11 w-11",
  12: "h-12 w-12",
  14: "h-14 w-14",
  16: "h-16 w-16",
  20: "h-20 w-20",
  24: "h-24 w-24",
  28: "h-28 w-28",
  32: "h-32 w-32",
  36: "h-36 w-36",
};
export default {
  name: "ProfileImage",
  data() {
    return {
      size_image,
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 28,
    },
  },
  computed: {
    set_size: function () {
      return this.size_image[this.size];
    },
  },
};
</script>
