<template>
  <button
    v-if="processing"
    class="btn select-none text-medium-xs text-gray-600 cursor-pointer hover:text-gray-800 opacity-60"
  >
    <i class="fal fa-spinner fa-spin" />
    {{ label }}
  </button>
  <button
    v-else
    class="btn select-none text-medium-xs text-gray-600 cursor-pointer hover:text-gray-800"
  >
    <i class="fal fa-save mr-0.5"></i>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "SaveButtonLink",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "Crear",
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
