<template>
  <button
    :class="[
      'px-4 py-2 border border-transparent rounded-full shadow-sm',
      'cursor-pointer text-medium-xs',
      cssButton(),
      { 'w-full': full },
    ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "PrimaryButton",
  data() {
    return {};
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    type_color: {
      type: String,
      default: "main",
    },
  },
  methods: {
    cssButton: function () {
      if (this.type_color == "main")
        return "bg-blue-600 hover:bg-blue-800 text-white";
      if (this.type_color == "strong")
        return "bg-blue-900 hover:bg-blue-950 text-white";
    },
  },
};
</script>
