<template>
  <destroy-button
    v-model="open"
    :label="label"
    :title="title"
    :message="message"
    @destroy-event="destroyEvent()"
  >
  </destroy-button>
</template>

<script>
export default {
  name: "DestroyButtonRails",
  data() {
    return {
      open: false,
      processing: false,
    };
  },
  props: {
    destroy_url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    destroyEvent: function () {
      this.processing = true;
      window.open(this.destroy_url, "_parent");
      this.open = false;
    },
  },
};
</script>
