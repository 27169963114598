<template>
  <div
    :class="[
      'flex items-center justify-center select-none',
      'border border-gray-400 rounded-full text-medium-xs text-gray-600',
      'cursor-pointer hover:border-gray-600 hover:text-gray-800',
      { 'w-full': full },
    ]"
  >
    <slot>
      <div class="flex items-center justify-center px-4 py-2">
        <span>{{ label }}</span>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: "CancelButton",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
