<template>
  <button
    :class="[
      'flex items-center justify-center px-4 py-2',
      'border border-gray-400 rounded-full text-medium-xs text-gray-600',
      'hover:border-gray-600 hover:text-gray-800',
      { 'w-full': full },
    ]"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "TabButton",
  data() {
    return {};
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
