import { createStore } from 'vuex'

const state = {}
const actions = ({})
const mutations = ({})
const getters = ({})

const modules = {}

export default createStore({
  state,
  actions,
  mutations,
  getters,
  modules
})
