import ProfileImage from "./ProfileImage.vue";
import SpaceActions from "./SpaceActions.vue";
import SpaceIcon from "./SpaceIcon.vue";
import YesNoLabel from "./YesNoLabel.vue";

export default {
  install: (App) => {
    App.component("profile-image", ProfileImage);
    App.component("space-actions", SpaceActions);
    App.component("space-icon", SpaceIcon);
    App.component("yes-no-label", YesNoLabel);
  },
};