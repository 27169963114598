<template>
  <div class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_authorization_type" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="sch_authorization[authorization_type_id]"
        :value="selected_authorization_type?.value"
      />
      <v-select
        :class="[
          invalid_authorization_type
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_authorization_type"
        :options="authorization_types"
      />
    </div>
  </div>
  <div v-if="by_school_level_id == selected_authorization_type?.value" class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_school_level" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="sch_authorization[school_level_id]"
        :value="selected_school_level?.value"
      />
      <v-select
        :class="[
          invalid_school_level
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_school_level"
        :options="school_levels"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectMemberType',
  data (){
    return {
      selected_authorization_type: this.original_selected_authorization_type,
      selected_school_level: this.original_selected_school_level,
    }
  },
  props: {
    original_selected_authorization_type: {
      type: Object,
      required: false,
    },
    authorization_types: {
      type: Array,
      required: true,
    },
    invalid_authorization_type: {
      type: Boolean,
      default: false,
    },
    original_selected_school_level: {
      type: Object,
      required: false,
    },
    school_levels: {
      type: Array,
      required: true,
    },
    invalid_school_level: {
      type: Boolean,
      default: false,
    },
    by_school_level_id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selected_authorization_type: function(newValue) {
      if(newValue === null) {
        this.selected_school_level = null;
        return;
      }
      if(this.by_school_level_id != newValue.value) {
        this.selected_school_level = null;
      }
    },
  },
}
</script>