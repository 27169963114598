<template>
  <TransitionRoot as="template" :show="sidebar_open">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40"
      @close="sidebar_open = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-800 bg-opacity-80 top-16" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="fixed flex-1 flex flex-col w-4/5 sm:w-1/4 pt-1 pb-4 bg-white top-16 bottom-0"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-16 pt-1">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                @click="sidebar_open = false"
              >
                <span class="sr-only">Close sidebar</span>
                <i class="fas fa-times fa-xl text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="mt-0 flex-1 h-0 overflow-y-auto">
            <!-- Menú vertical en el sidepanel izquierdo -->
            <nav class="px-2 space-y-1 pt-1">
              <template v-for="(menu, index) in menu_items" :key="index">
                <div
                  v-if="menu.submenus?.length > 0"
                  :class="[
                    'text-gray-600 hover:bg-blue-50 hover:text-blue-800 cursor-pointer',
                    'flex flex-col text-medium-sm rounded-md',
                    { 'bg-blue-50 text-blue-800': menu.open}
                  ]"
                >
                  <div 
                    class="flex flex-row items-center p-2 select-none"
                    @click.prevent="openSubmenu(menu)"
                  >
                    <div class="mr-4 flex-shrink-0 h-6 w-6">
                      <i :class="[menu.icon, 'fa-xl']"></i>
                    </div>
                    {{ menu.name }}
                    <div class="flex grow items-center justify-end">
                      <i v-if="menu.open" class="fas fa-chevron-up"></i>
                      <i v-else class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                  <div v-if="menu.open">
                    <template v-for="(submenu, idx) in menu.submenus" :key="idx">
                      <a
                        :href="submenu.url"
                        :class="[
                          'text-gray-600 hover:bg-blue-200 hover:text-blue-950 cursor-pointer',
                          'flex items-center p-2 text-medium-sm rounded-md',
                        ]"
                      >
                        <div class="mx-4 flex-shrink-0 h-6 w-6">
                          <i :class="[submenu.icon, 'fa-xl']"></i>
                        </div>
                        {{ submenu.name }}
                      </a>
                    </template>
                  </div>
                </div>
                <a
                  v-else
                  :href="menu.url"
                  :class="[
                    'text-gray-600 hover:bg-blue-50 hover:text-blue-800 cursor-pointer',
                    'flex items-center p-2 text-medium-sm rounded-md',
                  ]"
                >
                  <div class="mr-4 flex-shrink-0 h-6 w-6">
                    <i :class="[menu.icon, 'fa-xl']"></i>
                  </div>
                  {{ menu.name }}
                </a>
              </template>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="flex flex-col flex-1">
    <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 shadow bg-blue-950">
      <button
        type="button"
        class="px-4 text-white"
        @click="sidebar_open = true"
      >
        <span class="sr-only">Open sidebar</span>
        <i class="fal fa-bars fa-lg"></i>
      </button>
      <div class="flex-1 px-4 flex justify-between">
        <div class="flex bg-blue-950">
          <slot name="logo"></slot>
        </div>
        <div class="ml-4 flex items-center md:ml-6">
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div class="flex items-center space-x-2">
              <span class="hidden sm:block text-normal-xs text-gray-200">
                {{ admin_connected.name }} {{ admin_connected.last_name }}
              </span>
              <MenuButton
                class="max-w-xs bg-white flex items-center text-sm rounded-full"
              >
                <span class="sr-only">Open user menu</span>
                <profile-image
                  url="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  :size="8"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem
                  v-for="item in userMenu"
                  :key="item.name"
                  v-slot="{ active }"
                >
                  <a
                    :href="item.url"
                    :class="[
                      active ? 'bg-blue-50 text-blue-800' : '',
                      'block px-4 py-2 text-sm text-gray-700',
                    ]"
                  >
                    <i :class="[item.icon, 'fa-xl mr-2']"></i>
                    {{ item.name }}</a
                  >
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const userMenu = [
  { name: "Cerrar sesion", url: "/logout_admin", icon: "fas fa-sign-out" },
];

export default {
  name: "AppHeader",
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      sidebar_open: false,
      userMenu,
      menu_items: this.menus,
    };
  },
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    admin_connected: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openSubmenu: function (submenu) {
      submenu.open = !submenu.open;
    },
    close
  },
};
</script>
