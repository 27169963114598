import DatSubtitle from "./DatSubtitle.vue";
import DatText from "./DatText.vue";
import DatTextDanger from "./DatTextDanger.vue";
import DatTextPrimary from "./DatTextPrimary.vue";
import DatTitle from "./DatTitle.vue";

export default {
  install: (App) => {
    App.component("dat-subtitle", DatSubtitle);
    App.component("dat-text", DatText);
    App.component("dat-text-danger", DatTextDanger);
    App.component("dat-text-primary", DatTextPrimary);
    App.component("dat-title", DatTitle);
  },
};