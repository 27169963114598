import SchMembersSelectMemberType from "./members/SelectMemberType.vue";
import SchFamilyMembersSelectMemberType from "./family_members/SelectMemberType.vue";
import SchAuthorizationsSelectAuthorizationType from "./authorizations/SelectAuthorizationType.vue";

export default {
  install: (App) => {
    App.component("sch-members-select-member-type", SchMembersSelectMemberType);
    App.component("sch-family-members-select-member-type", SchFamilyMembersSelectMemberType);
    App.component("sch-authorizations-select-authorization-type", SchAuthorizationsSelectAuthorizationType);
  },
};