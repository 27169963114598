<template>
  <div class="relative">
    <input
      v-model="password"
      :name="field_name"
      :type="show_password ? 'text' : 'password'"
      :class="class_error"
    />
    <div
      class="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
    >
      <span
        @click.prevent="show_password = !show_password"
        :class="error ? 'text-red-500' : 'text-gray-500'"
      >
        <i v-if="show_password" class="fal fa-eye"></i>
        <i v-else class="fal fa-eye-slash"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordField",
  data() {
    return {
      show_password: false,
      password: "",
    };
  },
  props: {
    class_error: {
      type: String,
      required: true,
    },
    field_name: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
