<template>
  <button
    :class="[
      'px-4 py-2 border border-transparent rounded-full shadow-sm text-medium-xs',
      'cursor-not-allowed opacity-60',
      cssButton(),
      { 'w-full': full },
    ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "PrimaryButtonDisabled",
  data() {
    return {};
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cssButton: function () {
      return "bg-blue-600 text-white";
    },
  },
};
</script>
