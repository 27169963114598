<template>
  <span class="btn select-none text-medium-xs text-red-600 cursor-pointer hover:text-red-800">
    <i class="fal fa-times mr-0.5"></i>
    {{ label }}
  </span>
</template>

<script>
export default {
  name: "CancelButton",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "Cancelar",
    },
  },
};
</script>
