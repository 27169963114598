<template>
  <input
    type="hidden"
    :name="field_name"
    :value="selected_options.map((option) => option.value)"
  />
  <checkbox-field
    v-model="selected_options"
    :options="options"
    :invalid="invalid"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "CheckboxFieldRails",
  data() {
    return {
      selected_options: this.original_selected_options,
    };
  },
  props: {
    field_name: {
      type: String,
      required: true,
    },
    original_selected_options: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
