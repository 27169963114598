<template>
  <input
    type="hidden"
    :name="field_name"
    :value="selected_value?.value"
  />
  <v-select
    :class="[invalid ? 'custom-tailwind-select-is-invalid' : 'custom-tailwind-select']"
    v-model="selected_value"
    :options="options"
    :disabled="disabled ? disabled : false"
  />
</template>

<script>
export default {
  name: "SelectSearchField",
  data() {
    return {
      selected_value: this.selected_option,
    };
  },
  props: {
    field_name: {
      type: String,
      required: true,
    },
    selected_option: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
