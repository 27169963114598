import moment from "moment";
export default {
  methods: {
    decimalFormatter: function(value) {
      return value.replace(/[^-?\d.]/g, "").replace(/(\..*)\./g, "$1");
    },
    positiveDecimalFormatter: function(value) {
      return value.replace(/[^\d.]/g, "").replace(/(\..*)\./g, "$1");
    },
    integerFormatter: function(value) {
      return value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, "$1");
    },

    dateFormatter: function(
      date,
      output_format = "DD MMM YYYY",
      input_format = "YYYY-MM-DD"
    ) {
      if (date == null) return '';
      moment.locale("es");
      return moment(date, input_format)
        .format(output_format)
        .split(" ")
        .map(v => v.replace(".", ""))
        .join(" ");
    },

    dateTimeFormatter: function(
      date,
      output_format = "DD MMM YYYY HH:mm:ss",
      input_format = "YYYY-MM-DD HH:mm:ss"
    ) {
      if (date == null) return '';
      moment.locale("es");
      return moment(date, input_format)
        .format(output_format)
        .split(" ")
        .map(v => v.replace(".", ""))
        .join(" ");
    },

    dateFormatterDayMonthName: function(date) {
      return this.dateFormatter(date, "DD MMM");
    },

    dateFormatterYearMonthDay: function(date) {
      return this.dateFormatter(date, "YYYY-MM-DD");
    },

    dateFormatterWeek: function(date) {
      return moment(date).week();
    },

    getFormatCurrency: function(amount) {
      return amount.toLocaleString("es-PE", {
        style: "currency",
        currency: "pen",
        minimumFractionDigits: 1
      });
    },

    getFormatNumber: function(amount, digits = 0) {
      digits = amount % 1 == 0 ? 0 : digits;
      return amount.toLocaleString("es-PE", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      });
    },

    getFormatBytes: function (bytes, decimals = 2) {
      if (!bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    numberWithPrecision: function(number, precision) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      });

      return formatter.format(number);
    },

    decoratorDateTime: function(date_time) {
      moment.locale("es");
      var s_m = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_');
      moment.updateLocale('es', { monthsShort : s_m });
      return moment(date_time).utc(false).format("DD MMM YYYY, h:mm a")
    },
  }
};
