<template>
  <tabs-in-pills-with-border v-model="tab" :tabs="tabs" />
  <template v-for="(tb, idx) in tabs" :key="idx">
    <div class="pt-4">
      <slot v-if="tb.label == tab.label" :name="`tab_${idx}`" />
    </div>
  </template>
</template>

<script>
export default {
  name: "TabsInPillsRails",
  data() {
    return {
      tab: null,
      tabs: this.tab_options,
    };
  },
  props: {
    tab_options: {
      type: Array,
      required: true,
    },
    tab_selected: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    switchTab: function () {
      this.tab = this.tabs[this.tab_selected];
    },
    loadData: async function () {
      this.tabs.forEach((tab_option) => {
        if (
          tab_option?.label == this.tab?.label &&
          !tab_option.load_data
        ) {
          tab_option.load_data = true;
        }
      });
    },
  },
  created() {
    this.switchTab();
  },
  watch: {
    tab: {
      deep: true,
      handler: function (newValue) {
        this.tab = newValue;
        this.loadData();
      },
    },
  },
};
</script>
