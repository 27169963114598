<template>
  <div class="overflow-x-auto">
    <div class="inline-block min-w-full py-2 align-middle px-0.5">
      <div
        class="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-lg"
      >
        <table class="min-w-full">
          <thead class="bg-gray-100">
            <tr>
              <th v-if="number_rows" class="py-2 px-6 text-left">
                <ct-text :bold="true">#</ct-text>
              </th>
              <template v-for="(header, h_idx) in headers" :key="h_idx">
                <th class="py-2 px-6 text-left">
                  <slot
                    name="header"
                    v-bind="{
                      header: header,
                      column: number_rows ? h_idx + 1 : h_idx,
                    }"
                  >
                    <ct-text :bold="true">{{ header.label }}</ct-text>
                  </slot>
                </th>
              </template>
            </tr>
          </thead>
          <tbody class="bg-white">
            <template v-for="(row, idx) in rows" :key="idx">
              <tr :class="['border-gray-200', 'border-t']">
                <td
                  v-if="number_rows"
                  class="py-2 px-6 text-xs font-normal text-gray-500"
                >
                  <ct-text>{{ idx + 1 }}</ct-text>
                </td>
                <template v-for="(header, b_idx) in headers" :key="b_idx">
                  <td class="py-2 px-6 text-xs font-normal text-gray-500">
                    <slot
                      name="row"
                      v-bind="{
                        row: row,
                        key: header['key'],
                        column: number_rows ? b_idx + 1 : b_idx,
                      }"
                    >
                      <ct-text>{{ row[header['key']] }}</ct-text>
                    </slot>
                  </td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableInCard",
  data() {
    return {};
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    number_rows: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
