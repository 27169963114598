<template>
  <div :class="[{ 'text-red-600': invalid }]">
    <slot name="label">
      <label
        v-if="label"
        :class="[invalid ? 'form-label-invalid' : 'form-label']"
      >
        {{ label }}
      </label>
    </slot>
    <v-select
      :class="[
        invalid
          ? 'ct-custom-tailwind-select-is-invalid'
          : 'ct-custom-tailwind-select',
      ]"
      v-model="selected_value"
      :options="options"
      :disabled="disabled ? disabled : false"
      @click.prevent="$emit('change-event')"
    />
  </div>
</template>

<script>
export default {
  name: "SelectSearchField",
  emits: ["change-event", "update:modelValue"],
  data() {
    return {
      selected_value: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  updated() {
    this.selected_value = this.modelValue;
  },
  watch: {
    selected_value: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
