<template>
  <a
    :href="href"
    class="btn select-none text-medium-xs text-gray-600 cursor-pointer hover:text-gray-800"
  >
    <i class="fal fa-edit mr-0.5"></i>
    {{ label }}
  </a>
</template>

<script>
export default {
  name: "EditButtonLink",
  props: {
    href: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Editar",
    },
  },
};
</script>
