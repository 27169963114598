<template>
  <div class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_member_type" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="sch_member[member_type_id]"
        :value="selected_member_type?.value"
      />
      <v-select
        :class="[
          invalid_member_type
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_member_type"
        :options="member_types"
      />
    </div>
  </div>
  <div v-if="selected_member_type?.value === member_type_id" class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_current_school_level" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="sch_member[current_school_level_id]"
        :value="selected_current_school_level?.value"
      />
      <v-select
        :class="[
          invalid_current_school_level
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_current_school_level"
        :options="school_levels"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectMemberType',
  data (){
    return {
      selected_member_type: this.original_selected_member_type,
      selected_current_school_level: this.original_selected_current_school_level,
    }
  },
  props: {
    original_selected_member_type: {
      type: Object,
      required: false,
    },
    member_types: {
      type: Array,
      required: true,
    },
    invalid_member_type: {
      type: Boolean,
      default: false,
    },
    original_selected_current_school_level: {
      type: Object,
      required: false,
    },
    school_levels: {
      type: Array,
      required: true,
    },
    invalid_current_school_level: {
      type: Boolean,
      default: false,
    },
    member_type_id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selected_member_type: function(newValue) {
      if(newValue === null) {
        this.selected_current_school_level = null;
        return;
      }
      if(newValue.value !== this.member_type_id) {
        this.selected_current_school_level = null;
      }
    },
  },
}
</script>