import CheckboxFieldRails from "./CheckboxFieldRails.vue";
import DatePickerFieldRails from "./DatePickerFieldRails.vue";
import SelectSearchFieldRails from "./SelectSearchFieldRails.vue";
import PasswordFieldRails from "./PasswordFieldRails.vue";
import ToggleFieldRails from "./ToggleFieldRails.vue";

export default {
  install: (App) => {
    App.component("checkbox-field-rails", CheckboxFieldRails);
    App.component("date-picker-field-rails", DatePickerFieldRails);
    App.component("select-search-field-rails", SelectSearchFieldRails);
    App.component("password-field-rails", PasswordFieldRails);
    App.component("toggle-field-rails", ToggleFieldRails);
  },
};