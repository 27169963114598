// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
// import { Turbo } from "@hotwired/turbo-rails";
// Turbo.session.drive = false;

import { createApp } from "vue";
import store from "./../store";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

import "../../assets/stylesheets/application.tailwind.css";
import "../../assets/stylesheets/buttons.css"
import "../../assets/stylesheets/color.css"
import "../../assets/stylesheets/datepicker.css"
import "../../assets/stylesheets/forms.css"
import "../../assets/stylesheets/general.css"
import "../../assets/stylesheets/text.css"
import "../../assets/stylesheets/transition.css"
import '../../assets/stylesheets/vue-select.css';

// import custom components layouts
import layouts from "../components/layouts";

// import shared components
import Alerts from "./../components/shared/alerts";
import Buttons from "./../components/shared/buttons";
import ButtonsRails from "./../components/shared/buttons_rails";
import Forms from "./../components/shared/forms";
import FormsRails from "./../components/shared/forms_rails";
import Navigations from "./../components/shared/navigations";
import NavigationsRails from "./../components/shared/navigations_rails";
import Tables from "./../components/shared/tables";
import Typefaces from "./../components/shared/typefaces";
import Utils from "./../components/shared/utils";

// import views components
import Char from "./../components/views/char";
import Sch from "./../components/views/sch";

//mixins
import StandarErrorMessages from "./../components/shared/alerts/mixins/standard_error_messages.js";
import formatters from "../components/mixins/formatters.js";


const app = createApp({
  data() {
    return {
      message: "Hello Vue!",
    };
  },
});

app.use(store);

// import layouts components
app.use(layouts);

// import shared components
app.use(Alerts);
app.use(Buttons);
app.use(ButtonsRails);
app.use(Forms);
app.use(FormsRails);
app.use(Navigations);
app.use(NavigationsRails);
app.use(Tables);
app.use(Typefaces);
app.use(Utils);

// import views components
app.use(Char);
app.use(Sch);

app.component('v-select', vSelect)
app.component('v-date-picker', DatePicker)

// import mixins
app.mixin(StandarErrorMessages);
app.mixin(formatters);
app.mount("#app");
