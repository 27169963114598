<template>
  <div
    class="rounded-md bg-red-50 p-2"
    v-if="errors_data.display"
    :id="id ? id : 'standard_error_messages_alert'"
  >
    <div class="flex">
      <div class="flex-shrink-0 pt-1">
        <i class="fa-solid fa-circle-xmark text-red-400" aria-hidden="true"></i>
      </div>
      <div class="ml-3 pt-0.5">
        <h3 class="text-medium-xs text-red-800">
          {{ errors_data.title }}
        </h3>

        <div
          v-if="errors_data.message || errors_data.messages?.length > 0"
          class="mt-2 text-normal-xs text-red-600"
        >
          <p v-if="errors_data.message">{{ errors_data.message }}</p>
          <ul
            v-if="errors_data.messages.length > 0"
            role="list"
            class="list-disc pl-5 space-y-1"
          >
            <li
              v-for="(message_item, index_item) in errors_data.messages"
              :key="index_item"
            >
              {{ message_item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormErrorMessages",
  data() {
    return {};
  },
  props: {
    errors_data: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
};
</script>
