<template>
  <div>
    <label v-if="label" :class="[invalid ? 'form-label-invalid' : 'form-label']">
      {{ label }}
    </label>
    <div>
      <input
        type="text"
        :class="[invalid ? 'form-field-invalid' : 'form-field']"
        v-model="value"
        :placeholder="placeholder"
        :autofocus="autofocus"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputField",
  data() {
    return {
      value: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    autofocus: {
      type: String,
      required: false,
    },
  },
  updated() {
    this.value = this.modelValue;
  },
  watch: {
    value: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
