<template>
  <div class="relative">
    <Menu as="div" class="relative inline-block" v-slot="{ open }">
      <div>
        <MenuButton class="menu-button">
          <secondary-button
            v-if="options.find((opt) => opt == option_selected)"
          >
            <span>{{ label }}</span>
            <div class="ct-space-icon"></div>
            <div>
              <i v-if="open" class="fa-light fa-chevron-up" />
              <i v-else class="fa-light fa-chevron-down" />
            </div>
          </secondary-button>
          <tab-button v-else>
            <span>{{ label }}</span>
            <div class="ct-space-icon"></div>
            <div>
              <i v-if="open" class="fa-light fa-chevron-up" />
              <i v-else class="fa-light fa-chevron-down" />
            </div>
          </tab-button>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div v-show="open">
          <MenuItems
            class="absolute z-10 cursor-pointer select-none right-0 mt-2 w-52 text-normal-xs origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <div>
              <MenuItem
                v-for="(option, index) in options"
                :key="index"
                v-slot="{ active }"
              >
                <div
                  @click="switchOption(option)"
                  :class="[
                    active ? cssOption() : 'text-gray-500',
                    'group flex w-full items-center px-2 py-2 space-x-4 ',
                    { 'rounded-t': index == 0 },
                    { 'rounded-b': index == options.length - 1 },
                  ]"
                >
                  <div
                    v-if="option.fa_type"
                    class="flex items-center justify-center w-1/12"
                  >
                    <i :class="option.fa_type" />
                  </div>
                  <div class="flex items-center justify-start w-11/12">
                    <span>{{ option.label }}</span>
                  </div>
                </div>
              </MenuItem>
            </div>
          </MenuItems>
        </div>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default {
  name: "NavMenuDropdown",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  data() {
    return {
      option_selected: this.modelValue,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    cssOption: function () {
      return "bg-blue-600 text-white";
    },
    switchOption: function (option) {
      this.option_selected = option;
    },
  },
  updated() {
    this.option_selected = this.modelValue;
  },
  watch: {
    option_selected: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
