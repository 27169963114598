<template>
  <div
    :class="[
      { 'text-red-600': invalid },
      { 'cursor-not-allowed opacity-60': disabled },
    ]"
  >
    <slot name="label">
      <label
        v-if="label"
        :class="[invalid ? 'form-label-invalid' : 'form-label']"
      >
        {{ label }}
      </label>
    </slot>
    <div class="space-y-1">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="flex items-center"
      >
        <div class="flex items-center h-5">
          <input
            :id="`checkbox_${index}_${random_key}`"
            type="checkbox"
            class="focus:ring-blue-600 text-blue-600 h-4 w-4 border-gray-300 rounded cursor-pointer"
            :value="option.value"
            v-model="selected"
            :disabled="disabled"
          />
        </div>
        <div
          :class="[
            { 'text-red-600': invalid },
            'ml-3 text-normal-xs text-gray-500',
          ]"
        >
          <label
            :for="`checkbox_${index}_${random_key}`"
            class="cursor-pointer"
          >
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  data() {
    return {
      selected: this.modelValue?.map((option) => option.value),
      value: this.modelValue,
      random_key: Math.random(),
    };
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  updated() {
    this.value = this.modelValue;
  },
  watch: {
    selected: function (newVal) {
      let sel = this.options.filter((option) => newVal.includes(option.value));
      this.$emit("update:modelValue", sel);
    },
  },
};
</script>
