<template>
  <template v-if="only_icons">
    <div :class="['flex items-center', css_position]">
      <div class="flex flex-row flex-wrap gap-2">
        <template v-for="(tab, tabIdx) in tabs" :key="tabIdx">
          <secondary-button
            v-if="tab.icon == tab_selected?.icon"
            @click.prevent="switchTab(tab)"
            class="w-10 h-10"
          >
            <i :class="tab.icon"></i>
          </secondary-button>
          <tab-button
            v-else
            @click.prevent="switchTab(tab)"
            class="w-10 h-10"
          >
            <i :class="tab.icon"></i>
          </tab-button>
        </template>
      </div>
    </div>
  </template>
  <template v-else>
    <div :class="['sm:flex items-center', css_position]">
      <div
        class="flex flex-col sm:flex-row sm:gap-x-4 sm:items-center sm:gap-y-0 gap-y-1"
      >
        <template v-for="(tab, tabIdx) in tabs" :key="tabIdx">
          <secondary-button
            v-if="tab.label == tab_selected?.label"
            @click.prevent="switchTab(tab)"
          >
            <template v-if="tab.icon">
              <i :class="tab.icon"></i>
              <span class="ct-space-icon" />
            </template>
            <span class="text-center">{{ tab.label }}</span>
          </secondary-button>
          <tab-button v-else @click.prevent="switchTab(tab)">
            <template v-if="tab.icon">
              <i :class="tab.icon"></i>
              <span class="ct-space-icon" />
            </template>
            <span class="text-center">{{ tab.label }}</span>
          </tab-button>
        </template>
      </div>
    </div>
  </template>
</template>

<script>
const positions = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};
export default {
  name: "TabsOnlyBorder",
  data() {
    return {
      tab_selected: this.modelValue,
      positions,
    };
  },
  props: {
    modelValue: {
      type: Object,
    },
    tabs: {
      type: Array,
      required: true,
    },
    only_icons: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "center",
      validator: (value) => {
        return ["left", "center", "right"].includes(value);
      },
    },
  },
  methods: {
    switchTab: function (tab) {
      this.tab_selected = tab;
    },
  },
  computed: {
    css_position: function () {
      return this.positions[this.position];
    },
  },
  updated() {
    this.tab_selected = this.modelValue;
  },
  watch: {
    tab_selected: function (newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
