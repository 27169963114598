<template>
  <button
    :class="[
      'flex items-center justify-center px-4 py-2',
      'border rounded-full shadow-sm text-medium-xs',
      'cursor-pointer select-none',
      cssButton(),
      { 'w-full': full },
    ]"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "SecondaryButton",
  data() {
    return {};
  },
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    type_color: {
      type: String,
      default: "main",
    },
  },
  methods: {
    cssButton: function () {
      if (this.type_color == "main")
        return "border-blue-600 text-blue-600 hover:border-blue-800 hover:text-blue-800";
      if (this.type_color == "strong")
        return "border-blue-900 text-blue-900 hover:border-blue-950 hover:text-blue-950";
    },
  },
};
</script>
