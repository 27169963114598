<template>
  <span v-if="value" class="rounded bg-green-100 px-2 py-1 text-green-800 text-medium-xs">{{ label }}</span>
  <span v-else class="rounded bg-red-100 px-2 py-1 text-red-800 text-medium-xs">{{ label }}</span>
</template>

<script>
export default {
  name: "YesNoLabel",
  data() {
    return {};
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
}
</script>