<template>
  <input
    type="hidden"
    :name="field_name"
    :value="enabled"
  />
  <SwitchGroup as="div" class="flex items-center">
    <Switch
      v-model="enabled"
      :class="[
        enabled ? 'bg-blue-600' : 'bg-gray-300',
        'relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full ease-in-out duration-200',
      ]"
    >
      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      />
    </Switch>
    <SwitchLabel as="span" class="cursor-pointer">
      <space-icon />
      <label class="form-label cursor-pointer">{{ label }}</label>
    </SwitchLabel>
  </SwitchGroup>
</template>

<script>
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
export default {
  name: "ToggleFieldRails",
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  data() {
    return {
      enabled: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    field_name: {
      type: String,
      required: true,
    },
  },
};
</script>
