<template>
  <destroy-button v-model="open" :label="label" :title="title" :message="message" @destroy-event="destroyEvent()">
    <template #delete="{ label }">
      <div class="flex items-center">
        <div><i class="fal fa-trash mr-0.5"></i></div>
        <div>{{ label }}</div>
      </div>
    </template>
  </destroy-button>
</template>

<script>
export default {
  name: "DestroyButtonLinkRails",
  data() {
    return {
      open: false,
      processing: false,
    };
  },
  props: {
    destroy_url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    destroyEvent: function () {
      this.processing = true;
      window.open(this.destroy_url, "_parent");
      this.open = false;
    },
  },
};
</script>
