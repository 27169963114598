<template>
  <button
    :class="[
      'flex items-center justify-center px-4 py-2',
      'border rounded-full text-medium-xs',
      cssButton(),
      { 'w-full': full },
    ]"
  >
    <slot />
  </button>
</template>
<script>
export default {
  name: "DangerButton",
  data() {
    return {};
  },
  props: {
    type_color: {
      type: String,
      default: "primary",
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cssButton: function () {
      if (this.type_color == "primary")
        return "border-red-600 bg-red-600 text-white hover:border-red-800 hover:bg-red-800";
      if (this.type_color == "secondary")
        return "border-red-400 text-red-600 hover:border-red-600 hover:text-red-800";
    },
  },
};
</script>
