<template>
  <span class="text-bold-xl text-gray-900">{{ label }}</span>
</template>

<script>
export default {
  name: "DatTitle",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  }
}
</script>