<template>
  <span :class="[bold ? 'text-bold-xs' : 'text-normal-xs', get_type]">
    <slot />
  </span>
</template>

<script>
const types = {
  strong: "text-red-900",
  main: "text-red-600",
};
export default {
  name: "DatTextDanger",
  data() {
    return {};
  },
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
    type_color: {
      type: String,
      default: "main",
    },
  },
  computed: {
    get_type: function () {
      return types[this.type_color];
    },
  },
};
</script>
