<template>
  <div class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_data_type" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="char_characteristic[data_type_id]"
        :value="selected_data_type?.value"
      />
      <v-select
        :class="[
          invalid_data_type
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_data_type"
        :options="data_types"
      />
    </div>
  </div>
  <div v-if="selected_data_type?.value === data_type_list_id" class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_list" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="char_characteristic[dat_list_id]"
        :value="selected_list?.value"
      />
      <v-select
        :class="[
          invalid_dat_list
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_list"
        :options="dat_lists"
      />
    </div>
  </div>
  <div v-if="selected_data_type?.value === data_type_object_id" class="flex items-start">
    <div class="w-1/3 pt-1">
      <slot name="label_object" />
    </div>
    <div class="w-2/3">
      <input
        type="hidden"
        name="char_characteristic[object_reference_id]"
        :value="selected_object_reference?.value"
      />
      <v-select
        :class="[
          invalid_object_reference
            ? 'custom-tailwind-select-is-invalid'
            : 'custom-tailwind-select',
        ]"
        v-model="selected_object_reference"
        :options="object_references"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
let token = document.getElementsByName("csrf-token")[0].getAttribute("content");
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";
export default {
  data() {
    return {
      selected_data_type: this.original_selected_data_type,
      selected_list: this.original_selected_list,
      selected_object_reference: this.original_selected_object_reference,
    };
  },
  props: {
    original_selected_data_type: {
      type: Object,
      required: false,
    },
    data_types: {
      type: Array,
      required: true,
    },
    invalid_data_type: {
      type: Boolean,
      default: false,
    },
    original_selected_list: {
      type: Object,
      required: false,
    },
    dat_lists: {
      type: Array,
      required: true,
    },
    invalid_dat_list: {
      type: Boolean,
      default: false,
    },
    original_selected_object_reference: {
      type: Object,
      required: false,
    },
    object_references: {
      type: Array,
      required: true,
    },
    invalid_object_reference: {
      type: Boolean,
      default: false,
    },
    data_type_list_id: {
      type: Number,
      required: true,
    },
    data_type_object_id: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selected_data_type: function(newValue) {
      if(newValue === null) {
        this.selected_list = null;
        this.selected_object_reference = null;
        return;
      }
      if(newValue.value === this.data_type_list_id) {
        this.selected_object_reference = null;
      }
      if(newValue.value === this.data_type_object_id) {
        this.selected_list = null;
      }
    },
  },
};
</script>
