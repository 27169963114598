<template>
  <span :class="[bold ? 'text-bold-xs' : 'text-normal-xs', 'text-gray-600']">
    <slot />
  </span>
</template>

<script>
export default {
  name: "DatText",
  data() {
    return {};
  },
  props: {
    bold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
