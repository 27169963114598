<template>
  <input type="hidden" :name="field_name" :value="value" />
  <v-date-picker
    v-model="value"
    :format="format"
    locale="es"
    :mode="mode"
    hide-time-header
    :masks="masks"
    :popover="popover"
    :time-accuracy="3"
  >
    <template v-slot="{ inputValue, togglePopover, inputEvents }">
      <div class="relative rounded-md">
        <input
          type="text"
          :value="inputValue"
          :class="[
            invalid ? 'input-date-piker-is-invalid' : 'input-date-piker',
          ]"
          v-on="inputEvents"
        />
        <div
          :class="[
            'absolute inset-y-0 right-0 flex items-center cursor-pointer',
            invalid ? 'text-red-500' : 'text-gray-400',
          ]"
        >
          <i @click="value = null" class="fal fa-times w-5"></i>
          <i @click="togglePopover" class="fal fa-calendar w-5"></i>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>

<script>
import moment from 'moment';

const date_picker_value = {
  datetime: {
    format: 'DD/MM/YYYY HH:mm:ss',
    masks: { input: 'DD/MM/YYYY HH:mm:ss'}
  },
  date: {
    format: 'DD/MM/YYYY',
    masks: { input: 'DD/MM/YYYY'}
  },
  time: {
    format: 'HH:mm:ss',
    masks: { input: 'HH:mm:ss'}
  }
}

export default {
  name: "DatePickerFieldRails",
  data() {
    return {
      value: this.dateTimeFormatter(this.original_value || moment().toDate()),
      popover: { visibility: "click" },
    };
  },
  props: {
    field_name: {
      type: String,
      required: true,
    },
    original_value: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "date",
    },
  },
  computed: {
    format: function () {
      return date_picker_value[this.mode].format;
    },
    masks: function () {
      return date_picker_value[this.mode].masks;
    },
  }
};
</script>
