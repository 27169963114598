<template>
  <button
    class="btn select-none text-medium-xs text-gray-600 cursor-pointer hover:text-gray-800"
  >
    <i class="fal fa-edit mr-0.5"></i>
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "EditButton",
  props: {
    label: {
      type: String,
      default: "Editar",
    },
  },
};
</script>
